import React from "react";
import TextInput from "../../components/common/inputs/textInput";
import Loading from "../../components/common/loading";
import ModalManager from "../../components/common/modal/ModalManager";
import axios from "axios";
import { formatDistance } from "date-fns";

// import NotificationManager from '../../components/common/modal/NotificationManager'
import NotificationManager from "../../utils/notificationManager";

export default class LoggedInUser extends React.Component {
  state = {
    fullName: "",
    company: "",
    hasChanges: false,
    loading: false,
    newTokenFormActive: false,
    description: "",
    tokens: []
  };
  componentDidMount = async () =>  {
    if (this.props.user) {
      this.setState({
        fullName: this.props.user.full_name,
        company: this.props.user.company,
      });
      const tokens = await axios.get(`/v1/users/${this.props.user.id}/tokens`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        },
      })
      // console.log(tokens.data)
      this.setState({
        tokens: tokens.data.data
      })
    }
  }
  onCancel = () => {
    this.setState({
      hasChanges: false,
      fullName: this.props.user.full_name,
      company: this.props.user.company,
    });
  };

  onSave = () => {
    this.setState({
      // hasChanges: false,
      loading: true,
    });
    this.props.updateUserFetch(
      this.props.user,
      {
        full_name: this.state.fullName,
        company: this.state.company,
      },
      (err, res) => {
        if (err) {
          console.log(err);
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            hasChanges: false,
          });
        }
      }
    );
  };
  changePassClick = () => {
    ModalManager.confirm(
      "",
      `Change Password`,
      // "",
      "",
      (submitted, close) => {
        if (submitted) {
          close();
        }
      },
      ({ close }) => (
        <ChangePasswordForm
          close={close}
          resetPassword={this.props.resetPassword}
        />
      ),
      true,
      true
    );
  };
  createToken = () => {
    axios.post(`/v1/users/${this.props.user.id}/tokens`, {
      name: this.state.description
    }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      },
    }).then(res => {
      this.setState({
        newTokenFormActive: false,
        name: "",
        description: "",
        tokens: [...this.state.tokens, res.data.data]
      })
      NotificationManager.success("Token created", "Success")
      ModalManager.confirm(
        `Please copy the access token below. You will not be able to see it again.\n\n${res.data.data.token}`,
        "Personal Access Token",
        "Confirm",
        (submitted, close) => {
          if (submitted) {
            
            close()
          } else {
            // close();
          }
        }
      );
    }).catch(err => {
      NotificationManager.error("Error creating token", "Error")
    })
  }
  removeToken = (id) => {
    
    ModalManager.confirm(
      `Are you sure you want to permanently delete this Personal Access Token?`,
      "Delete Personal Access Token",
      "Confirm",
      (submitted, close) => {
        if (submitted) {
          axios.delete(`/v1/users/${this.props.user.id}/tokens/${id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
          }).then(res => {
            this.setState({
              tokens: this.state.tokens.filter(token => token.id !== id)
            })
            NotificationManager.success("Token removed", "Success")
          }
          ).catch(err => {
            NotificationManager.error("Error removing token", "Error")
          })
          close()
        } else {
          // close();
        }
      }
    );

  }
  toggleToken = (id) => {
    axios.put(`/v1/users/${this.props.user.id}/tokens/${id}`, {
      is_active: !this.state.tokens.find(token => token.id === id).is_active
    } ,{
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      },
    }).then(res => {
      this.setState({
        tokens: this.state.tokens.map(token => {
          if (token.id === id) {
            token.is_active = !token.is_active
          }
          return token
        })
      })
      NotificationManager.success("Token toggled", "Success")
    }
    ).catch(err => {
      NotificationManager.error("Error toggling token", "Error")
    })

  }
  render() {
    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x grid-padding-y">
          <div className="cell" style={{ paddingBottom: "0px", paddingTop: "0px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                opacity: this.state.hasChanges ? 1 : 0,
              }}
            >
              <button
                className="button"
                style={{
                  backgroundColor: "#eee",
                  color: "#111",
                  marginRight: "10px",
                }}
                onClick={() => {
                  this.onCancel();
                }}
              >
                Cancel
              </button>
              <button
                className="button"
                onClick={() => {
                  if (!this.loading) {
                    this.onSave();
                  }
                }}
              >
                {this.state.loading ? <Loading /> : "Save"}
              </button>
            </div>
          </div>
          <div className="cell large-6 " style={{ paddingTop: "0px"}}>
            <div>
              <h3>Profile</h3>
            </div>
            <div className="app-item" style={{ padding: "30px" }}>
              <TextInput
                label="Email"
                value={this.props.user.email_id}
                readOnly={true}
              />
              <TextInput
                label="Full Name"
                value={this.state.fullName}
                callback={(value) => {
                  this.setState({
                    fullName: value,
                  });
                  if (this.state.company !== value) {
                    this.setState({
                      hasChanges: true,
                    });
                  }
                }}
              />
              {/* <TextInput
                label="Company"
                value={this.state.company}
                callback={(value) => {
                  this.setState({
                    company: value,
                  });
                  if (this.state.company !== value) {
                    this.setState({
                      hasChanges: true,
                    });
                  }
                }}
              /> */}
              <div className="input-container">
                <div className="input-label">
                  <span>Password</span>
                </div>
                <button
                  className="button"
                  style={{}}
                  onClick={() => {
                    this.changePassClick();
                  }}
                >
                  Change Password
                </button>
              </div>


            </div>
          </div>
          <div className="cell large-6 " style={{ paddingTop: "0px"}}>

            <div>
              <h3>Security</h3>
            </div>
            <div className="app-item" style={{ padding: "30px" }}>

              <div>
                <div style={{
                  fontSize: "120%",
                  paddingBottom: "10px",
                  // marginBottom: "10px",
                  // borderBottom: "solid rgba(0,0,0,0.1) 1px"
                }}>
                  Personal Access Tokens
                </div>
                <div style={{
                  padding: "10px 0px"
                }}>
                  {this.state.tokens.map(tokenObj => {
                    return (
                      <div
                        style={{
                          borderBottom: "solid rgba(0,0,0,0.1) 1px",
                          paddingBottom: "3px",
                          marginBottom: "10px",
                        }}
                      >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          paddingBottom: "3px",
                          // marginBottom: "10px",
                          // borderBottom: "solid rgba(0,0,0,0.1) 1px"
                        }}
                      >
                        <div
                          key={tokenObj.id}
                          style={{
                            // paddingLeft: "10px",
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <div>
                            <b>
                            {tokenObj.name}
                            </b>
                            {!tokenObj.is_active &&
                              <span
                                style={{
                                  color: "maroon",
                                  paddingLeft: "5px"
                                }}
                              >
                                (Disabled)
                              </span>
                            }
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <div title="Disable/Enable this personall access token" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', paddingRight: "10px" }} onClick={() => {this.toggleToken(tokenObj.id)}}>
                              <span>
                                {!tokenObj.is_active ? "Enable": "Disable"}
                              </span>
                              {/* <i style={{ fontSize: "50px"}} className="material-icons">toggle_on</i> */}
                            </div>

                            <div title="Delete this personal access token" style={{ cursor: 'pointer', color: "grey",  }} onClick={() => {this.removeToken(tokenObj.id)}}>
                            <span>Delete</span>

                            {/* <i style={{ fontSize: "30px"}} className="material-icons-outlined">delete</i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          color: "grey",
                          fontSize: "85%",
                          // justifyContent: 'flex-end'
                        }}
                      >
                        Created {`${formatDistance(new Date(tokenObj.created_at), new Date())} ago`}
                        
                      </div>
                      </div>
                    )
                  })}
                </div>

                {!this.state.newTokenFormActive &&
                  <div className="input-container" style={{ marginBottom:"0px"}}>
                    <button
                      className="button"
                      style={{}}
                      onClick={() => {
                        this.setState({ newTokenFormActive: true })
                      }}
                    >
                      Create Token
                    </button>
                  </div>
                }
                {this.state.newTokenFormActive &&
                  <div
                    style={{
                      border: "solid rgba(0,0,0,0.07) 1px",
                      margin: "10px 0px",
                      padding: "10px",
                    }}
                  >
                    <div style={{
                      fontSize: "110%",
                      paddingBottom: "10px",
                      paddingTop: "10px"
                      // marginBottom: "10px",
                      // borderBottom: "solid rgba(0,0,0,0.1) 1px"
                    }}>
                      Create New Personal Access Token
                    </div>
                    <TextInput
                      label="Token Description"
                      value={this.state.description}
                      callback={(value) => {
                        this.setState({
                          description: value,
                        });
                      }}
                    />
                    <div className="input-container" style={{
                      marginBottom: "0px"
                    }}>
                      <button
                        className="button"
                        style={{}}
                        onClick={() => {
                          this.createToken();
                        }}
                      >
                        Save
                      </button>
                      <button
                        className="button"
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "rgba(0,0,0,0.1)",
                          color: "#222"
                        }}
                        onClick={() => {
                          this.setState({ newTokenFormActive: false, description: "" })
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ChangePasswordForm extends React.Component {
  state = {
    password: "",
    confirmPassword: "",
    currentPassword: "",
    errorMsg: "",
    loading: false,
  };
  resetPasswordButtonPress = () => {
    this.setState({
      errorMsg: "",
      loading: true,
    });
    if (this.state.password === this.state.confirmPassword) {
      this.props.resetPassword(
        {
          currentPass: this.state.currentPassword,
          password: this.state.password,
        },
        (err, res) => {
          this.setState({
            loading: false,
          });
          if (err) {
            this.setState({
              errorMsg: err.message,
            });
          } else {
            if (res.data) {
              this.props.close();
              NotificationManager.success("User Password Changed Successfully");
            }
          }
        }
      );
    } else {
      this.setState({
        loading: false,
        errorMsg: "Passwords do not match",
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <TextInput
          label="Current Password"
          callback={(text) => this.setState({ currentPassword: text })}
          value={this.state.currentPassword}
          inputContainerStyle={{
            width: "100%",
            marginBottom: "0px",
          }}
          type="password"
        />
        <TextInput
          label="Password"
          callback={(text) => this.setState({ password: text })}
          value={this.state.password}
          inputContainerStyle={{
            width: "100%",
            marginBottom: "0px",
          }}
          type="password"
        />
        <TextInput
          label="Confirm Password"
          callback={(text) => this.setState({ confirmPassword: text })}
          value={this.state.confirmPassword}
          inputContainerStyle={{
            width: "100%",
            marginBottom: "0px",
          }}
          type="password"
        />
        <div
          style={{
            color: "maroon",
            padding: "10px 0px 20px 0px",
          }}
        >
          <div>
            {[
              {
                name: "2 lowercase characters required",
                valid: /[a-z].*[a-z]/.test(this.state.password),
              },
              {
                name: "2 uppercase characters required",
                valid: /[A-Z].*[A-Z]/.test(this.state.password),
              },
              {
                name: "2 number characters required",
                valid: /[0-9].*[0-9]/.test(this.state.password),
              },
              {
                name: "2 special characters required",
                valid: /[!@#$%^&*;].*[!@#$%^&*;]/.test(this.state.password),
              },
            ].map((validation) => {
              return (
                <div
                  key={validation.name}
                  style={{
                    color: validation.valid ? "forestgreen" : "#666",
                    display: "flex",
                    paddingBottom: "10px",
                    alignItems: "center",
                  }}
                >
                  <i className="material-icons">
                    {validation.valid ? "check" : "cancel"}
                  </i>
                  <span>{validation.name}</span>
                </div>
              );
            })}
          </div>
          {this.state.errorMsg &&
            this.state.errorMsg.split(",").map((msg) => {
              return (
                <div key={msg}>
                  <div
                    style={{
                      color: "maroon",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{ marginRight: "5px" }}
                    >
                      error
                    </i>
                    <span>{msg}</span>
                  </div>
                </div>
              );
            })}
        </div>
        <button
          className="button"
          onClick={() => {
            this.resetPasswordButtonPress();
          }}
        >
          {this.state.loading ? <Loading /> : "Reset Password"}
        </button>
      </React.Fragment>
    );
  }
}
