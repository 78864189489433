
import React from 'react';
import Loading from '../../components/common/loading';
import RowList from '../../components/common/rowList';
import axios from "axios";
import NotificationManager from '../../utils/notificationManager';

export default class SamlAuthentication extends React.Component {
    state = {
        SAML_ENABLED: false,
        SAML_ENTITY_ID: "",
        SAML_LOGIN_URL: "",
        SAML_CERTIFICATE: "",
        name: "",
        max_num_seats: "100",
        just_in_time_provisioning_enabled: false,

        hasChanges: false,

        oktaInstructionsActive: false,
        generalInstructionsActive: false,

    }

    populateExistingEnterprise = () => {
        if (this.props.user.enterprise) {
            this.setState({
                ...this.props.user.enterprise,
            })
        
        }

    }

    componentDidMount() {
        if (!this.props.resources.groupsLoaded && !this.props.resources.groupsLoading) {
          this.props.fetchResource("groups");
        }
        this.populateExistingEnterprise()
    }



    onCancel = () => {
        console.log("cancel")
    }


    onSave = () => {

        const postPackage = {
            name: this.state.name,
            max_num_seats: this.state.max_num_seats,
            just_in_time_provisioning_enabled: this.state.just_in_time_provisioning_enabled,
            SAML_ENABLED: this.state.SAML_ENABLED,
            SAML_ENTITY_ID: this.state.SAML_ENTITY_ID,
            SAML_LOGIN_URL: this.state.SAML_LOGIN_URL,
            SAML_CERTIFICATE: this.state.SAML_CERTIFICATE,
            archived: this.state.archived,
        }
        this.props.modifyResource("enterprises", this.state.id, postPackage, (err, res) => {
            if (err) {
                console.log(err)
            } else {
                // console.log(res)
                if (res) {
                    console.log(res);

                } else {
                    console.log("ERRR")
                }
            }
        })
    }
    requestEnterpriseSetupEmail = (userId) => {
        if (window.confirm("Are you sure you want to send the setup email to this user?")) {
            const enterpriseId = this.props.match.params.enterpriseId
            axios.post(`/v1/enterprises/${enterpriseId}/users/${userId}/invite`, {}, {
                headers: {
                    authorization: "Bearer " + localStorage.getItem("token")
                }
            }).then(res => {
                // alert("Email sent")
                if (res.status == 200) {
                    NotificationManager.success(`Setup Email sent successfully`)
                } else {
                    NotificationManager.alert(`Error occurred sending email.`)
                }

            }).catch((err) => {
                console.log(err)
                NotificationManager.alert(`Error occurred sending email`)
            })
        }
    }

    modifyEnterpriseAdmin = (user) => {

        // if changing from is_enterprise_admin to non_is_enterprise_admin then deny and alert that at least one has to be. please select which one you want
        if (user.is_enterprise_admin) {
            // if there is only one enterprise admin then deny
            
        } else {
            if (window.confirm("Are you sure you want to change the enterprise admin? The PDUs that will be accessible will only be from those connected directly to that account.")) {

                const foundNewEnterpriseUser = this.state.enterpriseUsers.find(u => u.user_id == user.user_id)
                this.fetchUserDevices(foundNewEnterpriseUser.user_id)
                this.fetchUsergroups(foundNewEnterpriseUser.user_id)

                this.setState({
                    enterpriseUsers: this.state.enterpriseUsers.map(u => {
                        if (u.user_id == user.user_id) {
                            return {
                                ...u,
                                is_enterprise_admin: true,
                                has_admin_privileges: true
                            }
                        } else {
                            return {
                                ...u,
                                is_enterprise_admin: false,
                                has_admin_privileges: false
                            }
                        }
                    }),
                    hasChanges: true,
                })
            }
        }
    }

    render() {
        // const forNewEnterprise = this.props.match.params.enterpriseId === "new";
        // const deviceGroups = this.props.resources.groups.filter(g => g.groupType === "devices")

        return (
            <div className="grid-x grid-padding-x ">
                <div className="cell large-12" style={{ paddingTop: "10px" }}>
                    <div
                        style={{
                            paddingLeft: "10px",
                            // marginBottom: "10px",
                            fontSize: "18px",
                            fontWeight: "600",
                            color: "#333",
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <div>Single Sign On (SSO) Authentication</div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                opacity: this.state.hasChanges ? 1 : 0,
                            }}
                        >
                            {/* <button
                                className="button"
                                style={{
                                    backgroundColor: "#eee",
                                    color: "#111",
                                    marginRight: "10px",
                                }}
                                onClick={() => {
                                    this.onCancel();
                                }}
                            >
                                Cancel
                            </button> */}
                            <button
                                className="button"
                                onClick={() => {
                                    if (!this.loading) {
                                        this.onSave();
                                    }
                                }}
                            >
                                {this.state.loading ? <Loading /> : "Save"}
                            </button>
                        </div>
                    </div>

                    <div
                        style={{
                            paddingLeft: "10px",
                            marginBottom: "20px"
                        }}
                    >
                        <div>Fill these steps to set up SAML 2.0 Single Sign On (SSO) Authentication for enhanced security and a streamlined login experience.
                        You will need an Identity Provider (IdP) to begin this process. The IdP stores and verifies user identity information (Azure AD, Okta, Google workspace).</div>
                        <br />
                        <div>Once activated, your team members will be able to access the software product using their SAML SSO credentials, providing a secure and efficient way to manage team access.</div>
                        
                        
                        <div
                            style={{ margin: "10px 0px", cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }} 

                        >
                            <b>Instructions For SAML Connection set up with your preferred Identity Provider (IdP)</b> 
                        </div>
                        <div>
                            1. Create and set up an IdP application providing the SAML Assertion Consumer Service (ACS) URL and Entity ID provided below
                        </div>
                        <div>
                            2. Copy and paste the following settings from your IdP to the form below: Identity Provider Issuer, SAML SSO Login URL, and Public Certificate
                        </div>
                        <div>
                            3. Enable SAML 2.0 and save the settings
                        </div>
                        <div>
                            4. Assign users to the application on your IdP
                        </div>
                        <div>
                            5. Create the assigned user from the IdP on the Synaccess Cloud Administrator platform
                        </div>
                    </div>
                </div>
                <div className="cell">
                    <div
                        style={{
                            paddingLeft: "10px",
                            paddingBottom: "10px",
                            fontWeight: "600",
                            fontSize: "16px"
                        }}
                    >
                        SAML 2.0 Settings
                    </div>
                    <RowList
                        rows={[
                            {
                                label:
                                    <div>
                                        <div>SAML Enabled</div>
                                    </div>,
                                valueComponent: (
                                    <DashButton
                                        text={this.state.SAML_ENABLED ? "Enabled" : "Disabled"}
                                        color={this.state.SAML_ENABLED ? "rgb(6,100,6)" : "maroon"}
                                        backgroundColor={this.state.SAML_ENABLED ? "rgb(234, 247, 234)" : "rgb(247, 234, 234)"}
                                        icon={this.state.SAML_ENABLED ? "toggle_on" : "toggle_off"}
                                        onClick={() => {
                                            this.setState({
                                                SAML_ENABLED: !this.state.SAML_ENABLED,
                                                hasChanges: true,
                                            })
                                        }}
                                    />
                                )
                            }, {
                                label:
                                    <div>
                                        <div>Identity Provider Issuer</div>
                                        <div style={{ fontSize: "14px", color: "#999" }}></div>
                                    </div>,
                                valueComponent: (
                                    <input
                                        type="text"
                                        value={this.state.SAML_ENTITY_ID}
                                        onChange={e => {
                                            this.setState({
                                                SAML_ENTITY_ID: e.target.value,
                                                hasChanges: true,
                                            })
                                        }}
                                        placeholder="https://example.com"
                                    />
                                )
                            }, {
                                label:
                                    <div>
                                        <div>SAML SSO Login URL</div>
                                        <div style={{ fontSize: "14px", color: "#999" }}>Enter the URL provided by your IdP where SAML authentication requests should be sent. This is typically an endpoint on the IdP's servers.</div>
                                    </div>,
                                valueComponent: (
                                    <input
                                        type="text"
                                        value={this.state.SAML_LOGIN_URL}
                                        onChange={e => {
                                            this.setState({
                                                SAML_LOGIN_URL: e.target.value,
                                                hasChanges: true,
                                            })
                                        }}
                                        placeholder="https://example.com"
                                    />
                                )
                            }, {
                                label:
                                    <div>
                                        <div>SAML Certificate</div>
                                        <div style={{ fontSize: "14px", color: "#999" }}>Copy and  the certificate from your IdP. This certificate is used to securely sign your SAML messages and ensure they are coming from a trusted source.</div>
                                    </div>,
                                valueComponent: (
                                    <textarea
                                        type="text"
                                        value={this.state.SAML_CERTIFICATE}
                                        onChange={e => {
                                            this.setState({
                                                SAML_CERTIFICATE: e.target.value,
                                                hasChanges: true,
                                            })
                                        }}
                                        rows={this.state.SAML_CERTIFICATE.length === 0 ? 10 : 2}
                                        placeholder="-----BEGIN CERTIFICATE-----"
                                    />
                                )
                            }, {
                                label:
                                <div>
                                    <div>SAML Assertion Consumer Service (ACS) URL</div>
                                    <div style={{ fontSize: "14px", color: "#999" }}>The location that the SAML Assertion is sent with a HTTP Post. Also known as the SSO post-back up URL. You will need to configure your IdP with this URL.</div>
                                </div>,
                                valueComponent: (
                                    <div
                                        style={{
                                            padding: "10px",
                                            backgroundColor: "#f8f8f8",
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                            color: "#666",
                                            width: "100%"
                                        
                                        }}
                                    >
                                        https://cloud.synaccess.com/sso/saml/{this.state.enterprise_id}
                                    </div>
                                )
                            }, {
                                label: 
                                <div>
                                    <div>Entity ID</div>
                                    <div style={{ fontSize: "14px", color: "#999" }}>Also used as the audience restriction.</div>

                                </div>,
                                valueComponent: (
                                    <div
                                        style={{
                                            padding: "8px",
                                            backgroundColor: "#f8f8f8",
                                            borderRadius: "5px",
                                            fontSize: "14px",
                                            color: "#666",
                                            width: "100%"
                                        
                                        }}
                                    >
                                        https://cloud.synaccess.com
                                    </div>
                                )
                            }
                        ]}
                    />
                    <div style={{ paddingTop: "35px", marginBottom: "20px", paddingLeft: "10px", paddingRight: "10px" }}>
                        <div>
                            <div
                                style={{
                                    fontSize: "14px",
                                    color: "#555",
                                }}
                            >
                                <div
                                    style={{
                                        // paddingLeft: "10px",
                                        marginBottom: "10px",
                                        // fontSize: "18px",
                                        // fontWeight: "600",
                                        fontSize: "16px",
                                        color: "#666",
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            oktaInstructionsActive: !this.state.oktaInstructionsActive
                                        })
                                    }}
                                >
                                    <div>Instructions for Okta Identity Provider Configuration</div>
                                    <div
                                    >
                                        <i className="material-icons">
                                            {this.state.oktaInstructionsActive ? "expand_less" : "expand_more"}
                                        </i>
                                    </div>
                                </div>
                                {this.state.oktaInstructionsActive && 
                                    <div
                                        style={{
                                            color: "#666"
                                        }}
                                    >
                                        <div
                                            style={{ fontSize: "110%", marginBottom: "10px", textDecoration: "underline" }}
                                        >
                                            <b>Set Up Okta IdP Application Settings</b>
                                        </div>
                                        <div>1. As an Okta Administrator, Create an App Integration under Applications -> Create App Integration</div>
                                        <div>2. Select SAML 2.0 as the Sign on method</div>
                                        <div>3. Configure the following settings:</div>
                                        <div style={{ marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}>
                                            <div><b>Single Sign On URL:</b> https://cloud.synaccess.com/sso/saml/{this.state.enterprise_id}</div>
                                            <div><b>Audience URI (SP Entity ID):</b> https://cloud.synaccess.com</div>
                                            <div><b>Name ID format:</b> EmailAddress</div>
                                            <div><b>Application username:</b> Email</div>
                                        </div>
                                        <div>4. Assign users to application</div>
                                        <div>5. Create the assigned user from Okta on the Synaccess Cloud Administrator platform</div>
                                        <div
                                            style={{ fontSize: "110%", marginTop: "10px", marginBottom: "10px", textDecoration: "underline" }}
                                        >
                                            <b>Set Up Synaccess Cloud Service Provider Settings with Okta IdP Settings</b>
                                        </div>
                                        <div>
                                            1. Copy the following settings from the Okta IdP settings to the Synaccess Cloud Service Provider settings on this page
                                        </div>
                                        <div style={{ marginLeft: "10px", marginTop: "5px" }}>
                                            <div><b>Identity Provider Issuer</b> can be found on the same page where the sign-on URL is accessed as "Issuer".</div>
                                            <div><b>SAML SSO Login URL</b> is found from the "Sign On" tab inside the app settings inside Okta. The Identity Provider Single Sign-On URL can be found inside the "View Setup Instructions" button as "Sign on URL".</div>
                                            <div><b>SAML Certificate</b> Found on the same page where the sign-on URL is as "Signing Certificate".</div>
                                        </div>

                                        
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const DashButton = ({
    text,
    color,
    icon,

    onClick = () => { },
}) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',

        }}
    >
        <span
            style={{
                color,
                padding: "3px 5px",
                fontWeight: "500",
                borderRadius: "5px",
                textAlign: "center",
                display: 'flex',
                alignItems: 'center',
                height: "10px",
                cursor: 'pointer'
            }}
            onClick={onClick}
        >
            <span
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <i className="material-icons" style={{ fontSize: "40px", color, }}>{icon}</i>
            </span>
            <span style={{ marginLeft: "10px" }}>{text}</span>
        </span>
    </div>
);