import React from 'react';

const ToggleInput = ({
  label,
  callback = () => {},
  value,
  disabled,
  inputContainerStyle = {},
  id,
}) => (
  <div
    className="input-container"
    style={{
      marginBottom: "20px",
      ...inputContainerStyle,
    }}
  >
    {label &&
      <div
        className="input-label"
        style={{
          color: disabled ? "#9e9e9e" : "#333",
          paddingBottom: '5px'
        }}
      >
        {label}
      </div>
    }
    <input
      className="tgl tgl-light"
      id={`cb_${label || id}`} // NEEDS TO BE UNIQUE
      type="checkbox"
      checked={!!value}
      onChange={() => {
        callback(!value)
      }}
      disabled={disabled}
    />
    <label
      className="tgl-btn"
      htmlFor={`cb_${label || id}`}
    />
  </div>
);

export default ToggleInput;