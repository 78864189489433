 import DeviceOutlets from './devices/deviceOutlets';
import DeviceInlets from './devices/deviceInlets';
import DeviceAlerts from './devices/deviceAlerts';
import DeviceConnected from './devices/deviceConnected';
import DeviceSensors from './devices/deviceSensors';

import LoggingUserLogs from './logging/userLogs';
import LoggedInUser from './users/loggedInUser';
import UsersBilling from './users/usersBilling';
import UserSAML from './users/samlAuthentication'
import UserTeamMembers from './users/userTeamMembers'

import {Redirect} from 'react-router-dom'

import Loading from '../components/common/loading';
import ConnectedDevicePage from './devices/deviceConnectedPage';
import DeviceAlertItemPage from './devices/deviceAlertsItemPage'

const pages = (
  // device, 
  // conf,
  // user = {
  //   role: {
  //     permissions: {
  //       viewOutlets: []
  //     }
  //   }
  // }
  user = {}
) => [
...unlistedPages().map(p => ({
  ...p,
  unlisted: true,
  exact: true
})), {
  headerTitle: "Devices",
  headerIcon: "cloud_queue",
  basePath: "/devices",
  defaultPath: "/devices/connected",
  sections: [{
    label: "Connected",
    icon: "public",
    path: "/devices/connected",
    component: DeviceConnected
  }, {
    label: "Power Consumption",
    icon: "power",
    path: "/devices/inlets",
    component: DeviceInlets,
  }, {
    label: "Manage Outlets",
    icon: "outlet",
    path: "/devices/outlets",
    component: DeviceOutlets
  // }, {
  //   label: "Banks",
  //   icon: "settings_input_component",
  //   path: "/devices/banks",
  //   component: DeviceBanks,
  }, {
    label: "Sensors",
    icon: "device_thermostat",
    path: "/devices/sensors",
    component: DeviceSensors,
  }, {
    label: "Alerts",
    icon: "notifications",
    path: "/devices/alerts",
    component: DeviceAlerts,
  }]
}, {
  headerTitle: "Activity",
  headerIcon: 'date_range',
  defaultPath: "/activity/history",
  basePath: "/activity",
  sections: [{
  //   label: "Notifications",
  //   icon: "notifications",
  //   path: "/activity/notifications",
  //   component: LoggingEventLogs
  // }, {
    label: "History",
    icon: "history",
    path: "/activity/history",
    component: LoggingUserLogs
  }]
}, {
  headerTitle: "Settings",
  headerIcon: "settings",
  basePath: "/users",
  defaultPath: "/users/logged-in",
  sections: [{
    label: "Profile",
    icon: "account_circle",
    path: "/users/logged-in",
    component: LoggedInUser,
  },{
    label: "Billing",
    icon: "credit_card",
    path: "/users/billing",
    component: UsersBilling,
    // disabled: !user.enterprise
    disabled: !user.is_enterprise_admin
  }, {
    label: "Team Members",
    icon: "supervisor_account",
    path: "/users/team-members",
    component: UserTeamMembers,
    disabled: !user.enterprise || !user.enterprise.enterpriseUsers
  }, {
    label: "SAML Auth",
    icon: "vpn_key",
    path: "/users/enterprise",
    component: UserSAML,
    disabled: !user.is_enterprise_admin

    // disabled: !user.enterprise
  }]

// }, {
//   headerTitle: "Settings",
//   headerIcon: "settings",
//   basePath: "/settings",
//   defaultPath: "/settings/lcd",
//   sections: [{
//     label: "Mining",
//     icon: "bitcoin",
//     path: "/settings/mining",
//     component: settingsBitcoin,
//     disabled: !conf.hasOwnProperty('slushPoolAuthToken')// commented out for demo. want to show these exist, while not giving permissions
//   }]
}]

const unlistedPages = () => [
  {
    basePath: "/devices/outlets/:outletId",
    component: ({ 
      resources: {
        outlets, outletsLoading
      }, 
      match 
    }) => {
      if (outletsLoading) {
        return <Loading black />
      } else {
        const outlet = outlets.find(o => o.id === match.params.outletId)
        if (!outlet) {
          return "Cannot find outlet"
        } else {
          return <Redirect to={`/devices/connected/${outlet.enclosureSerialNumber}/outlets/${match.params.outletId}`}/>
        }
      }

    }
  },
  {
    headerTitle: "Connected PDU",
    basePath: "/devices/connected/:enclosureSerialNumber",
    component: props => <Redirect to={`/devices/connected/${props.match.params.enclosureSerialNumber}/details`} />,
    headerIcon: "power"
  }, {
    headerTitle: "Connected PDU",
    basePath: "/devices/connected/:enclosureSerialNumber/alerts/:eventId",
    headerIcon: "notifications",
    component: DeviceAlertItemPage
  }, {
    headerTitle: "Connected PDU",
    basePath: "/devices/connected/:enclosureSerialNumber/:section",
    component: ConnectedDevicePage,
    headerIcon: "power"
  },{
    headerTitle: "Connected PDU",
    basePath: "/devices/connected/:enclosureSerialNumber/:section/:section_id",
    component: ConnectedDevicePage,
    headerIcon: "power"
  }
]

export default pages;